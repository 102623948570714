import { Coin, MsgSnip20Send, TxResponse, fromBase64} from "secretjs";
import { AnyMsg, BankTransferMessage, ExecuteMsg, IbcTransferMsg, MatchMessage } from "../Types/messages";
import { match } from "ts-pattern";
import { findDenom } from "./denoms";
import { findLog, getTokenInfo } from "./querier";
import { Snip20HandleMsg, TokenInfo } from "../Types/snip20";
import { stringToCoin, truncate } from ".";
import contracts from '../stores/contracts.json'
import { ContractInfo, PairInfo } from "../Types/contracts";
import { Snip20SendOptions } from "secretjs/dist/extensions/snip20/types";
import {Buffer} from 'buffer';

const ShadeSwapFactory = 'secret1ja0hcwvy76grqkpgwznxukgd7t8a8anmmx05pp'
const ShadeSwapRouter = 'secret1pjhdug87nxzv0esxasmeyfsucaj98pw4334wyc'
const AlterPayContract = 'secret1tvhnydaakdp5tg2jhedhmc88nryjefn4j5dup2'

// let shadePairs: ShadeRouterAmmPair[] = [];
// const getShadePairs = async () => {
//   const res = await querierJs.query.compute.queryContract({
//     contract_address: ShadeSwapFactory,
//     query: {
//       "list_a_m_m_pairs": {
//         "pagination": {
//           "start": 0,
//           "limit": 100
//         }
//       }
//     }
//   }) as GetShadeRouterPairsResponse;
//   if (typeof(res) === 'string') throw res;
//   shadePairs = res.list_a_m_m_pairs.amm_pairs
//   console.log(shadePairs)
// }
// getShadePairs();


interface PartMsgResponse {
  type: string,
  amount: string[],
}

export interface MsgResponse extends PartMsgResponse {
  hash: string,
  msgIndex: number,
  height: number,
  time: string,
}

const processTx = async (tx: TxResponse, connectedWallet: string): Promise<MsgResponse[]> => {
  const txs: MsgResponse[] = [];
  if (!tx.tx.body) throw new Error(`tx.tx.body is undefined for tx ${tx.transactionHash}`)
  if (!tx.tx.body.messages) throw new Error(`tx.tx.body is undefined for tx ${tx.transactionHash}`)

  for (const [msgIndex, msg] of tx.tx.body.messages.entries()) {
    const msg = tx.tx.body.messages[msgIndex]
    txs.push({
      ...(await processMsg(msg as MatchMessage, connectedWallet, tx)),
      hash: tx.transactionHash,
      msgIndex,
      height: tx.height,
      time: new Date().toLocaleDateString()
  })
  }

  return txs;
}

const processDenomAmounts = (coins: Coin[], isSender = true): string[] => {
  return coins.map((coin: Coin)=>{
    const denom = findDenom(coin.denom);
    const symbol = denom?.symbol || coin.denom;
    const decimals = denom?.decimals || 0;
    const float = parseInt(coin.amount) / Math.pow(10, decimals)
    const prefix = isSender ? '-' : '+'
    return `${prefix}${float} ${truncate(symbol)}`
  })
}

const processMsg = async (msg: MatchMessage, connectedWallet: string, tx: TxResponse): Promise<PartMsgResponse> => {
  //@ts-ignore
  return match(msg)
    .with({ "@type": '/secret.compute.v1beta1.MsgExecuteContract' }, async (msg: ExecuteMsg) => {
      return await handleExecuteMsg(msg, tx);
    })
    .with({ "@type": "/cosmos.bank.v1beta1.MsgSend" }, ({ amount, from_address, to_address }: BankTransferMessage) => {
      const isSender = from_address === connectedWallet
      const amounts = processDenomAmounts(amount, isSender)
      
      return {
        type: isSender ? 'Send' : 'Receive',
        amount: amounts,
      }
    })
    .with({ "@type": "/ibc.applications.transfer.v1.MsgTransfer" }, ({ sender, receiver, token: {amount, denom} }: IbcTransferMsg) => {
      const isSender = sender === connectedWallet
      const amounts = processDenomAmounts([{amount, denom}], isSender)
      return {
        type: isSender ? 'IBC Send' : 'IBC Receive',
        amount: amounts,
      }
    })
    .otherwise((msg: AnyMsg) => {
      return {
        type: msg['@type'],
        amount: []
      }
    })
}

const handleExecuteMsg = async (message: ExecuteMsg, tx: TxResponse): Promise<PartMsgResponse> => {
  const {contract, msg, sent_funds} = message;
  // Handle Known Contracts
  // None to handle...

  //Handle SNIP20s
  const tokenInfo = await getTokenInfo(contract);
  if (tokenInfo) return handleSnip20(message, tx);

  const handle = typeof(msg) === 'string' ? 'Unable to Decrypt' : Object.keys(msg)[0]

  if (contract === AlterPayContract) return handleAlterPayExecute(message, tx, handle);

  return {
    type: `Execute: ${handle}`,
    amount: processDenomAmounts(sent_funds),
  }
}

const handleSnip20 = async (execMsg: ExecuteMsg, tx: TxResponse): Promise<PartMsgResponse> => {
  const {contract, msg, sent_funds} = execMsg;
  const tokenInfo = await getTokenInfo(contract);

  //@ts-ignore
  return match(msg)
    .when((msg: any) => Object.keys(msg)[0] === 'send', (msg: Snip20SendOptions)=>{
      if (!msg.send) throw new Error('FUCK')

      const amount = parseInt(msg.send.amount) / Math.pow(10, tokenInfo.decimals)


      const shadePairContract: ContractInfo | undefined = contracts.find(c=>c.address === (msg as any).send.recipient && !!c.shadeSwapPair)

      if (shadePairContract) {
        if (!shadePairContract.shadeSwapPair) throw 'idk'
        return handleShadeSwap(execMsg, tx, amount, shadePairContract.shadeSwapPair, tokenInfo)
      }

      if (msg.send.recipient === ShadeSwapRouter) {
        return handleShadeRouterSwap(execMsg, tx, amount, tokenInfo)
      }

      if (msg.send.recipient === AlterPayContract) {
        return handleAlterPayDeposit(execMsg, tx, amount, tokenInfo);
      }

      // Handle if callback to contract
      if ((msg as Snip20SendOptions).send.msg) {
        return {
          type: `SNIP20: Send and Execute Contract: ${(msg as Snip20SendOptions).send.recipient}`,
          amount: [`-${amount} ${tokenInfo.symbol}`],
        }
      }

      return {
        type: 'SNIP20: Transfer',
        amount: [`-${amount} ${tokenInfo.symbol}`],

      }
    })
    .when((msg: any) => Object.keys(msg)[0] === 'transfer', (msg: Snip20HandleMsg)=>{
      // this type matching sucks
      if (!msg.transfer) throw new Error('FUCK')

      //@ts-ignore fucking typescript
      const amount = parseInt(msg.transfer.amount) / Math.pow(10, tokenInfo.decimals || 0)
      return {
        type: 'SNIP20: Transfer',
        amount: [`-${amount} ${tokenInfo.symbol}`],
      }
    })
    .when((msg: any) => Object.keys(msg)[0] === 'redeem', (msg: Snip20HandleMsg)=>{
      // this type matching sucks
      if (!msg.redeem) throw new Error('FUCK')

      //@ts-ignore fucking typescript
      const amount = parseInt(msg.redeem.amount) / Math.pow(10, tokenInfo.decimals || 0)
      const received = findLog(tx.arrayLog, 'coin_received', 'amount')
      if (!received) throw `Unable to find recieved amount for IBC TX ${tx.transactionHash}`

      const receivedCoin = stringToCoin(received);
      const receivedAmount = processDenomAmounts([receivedCoin], false)
      return {
        type: 'SNIP20: Unwrap',
        // amount: [`-${amount} ${tokenInfo.symbol}`, `+${receivedCoin?.amount} ${truncate(receivedCoin?.denom || 'Unknown')}`],
        amount: [`-${amount} ${tokenInfo.symbol}`, ...receivedAmount]
      }
    })
    .when((msg: any) => Object.keys(msg)[0] === 'deposit', async (msg: Snip20HandleMsg)=>{
      // this type matching sucks
      if (!msg.deposit) throw new Error('FUCK')

      const sent = processDenomAmounts(sent_funds)
      const amount = parseInt(sent_funds[0].amount) / Math.pow(10, tokenInfo.decimals)
      return {
        type: 'SNIP20: Wrap',
        amount: [...sent, `+${amount} ${tokenInfo.symbol}`],
      }
    })
    .otherwise((msg: any) => {
      if (typeof msg === 'string') {
        return {
          type: `SNIP20 ${tokenInfo.symbol}: Failed to Decrypt`,
          amount: [''],
        }
      }

      return {
        type: `SNIP20 ${tokenInfo.symbol}: ${Object.keys(msg)[0]}`,
        amount: [''],
      }
    }) as PartMsgResponse


}

const handleShadeSwap = async ({contract, msg, sent_funds}: ExecuteMsg, tx: TxResponse, amount: number, shadePair: PairInfo, tokenInfo: TokenInfo): Promise<PartMsgResponse> => {
  const destination = shadePair.tokens.find(a=>a !== contract);
  const destSnip20 = contracts.find(c=>c.address === destination);

  return {
    type: 'Shade Protocol: Swap',
    amount: [`-${amount} ${tokenInfo.symbol}`],
  }
}

const handleShadeRouterSwap = async ({contract, msg, sent_funds}: ExecuteMsg, tx: TxResponse, amount: number, tokenInfo: TokenInfo): Promise<PartMsgResponse> => {
  if (!(msg as Snip20SendOptions).send.msg) throw `Router swap ${tx.transactionHash} doesnt have a snip20 callback message?!`
  const callbackMsg = JSON.parse(Buffer.from((msg as Snip20SendOptions).send.msg as string, 'base64').toString());
  const amountOut = findLog(tx.arrayLog, 'wasm', 'amount_out')
  if (!amountOut) throw `Router swap ${tx.transactionHash} unable to find amount_out in logs?!`

  let destSwap = callbackMsg.swap_tokens_for_exact.path[0].addr;
  // Set to token we currently have
  let destSnip20 = contract;
  // `path` is array of {addr, code_hash} of swap contracts
  for (const {addr} of callbackMsg.swap_tokens_for_exact.path){
    // Get pair info and 
    const pairInfo = contracts.find(c=>c.address === addr)?.shadeSwapPair;
    if (!pairInfo) {
      console.error(contracts.find(c=>c.address === addr))
      throw `Intermediate pair in router swap ${tx.transactionHash} contains unknown pair in route: ${addr}`
    }

    // find token we are swapping for
    const other = pairInfo.tokens.find(t=>t !== destSnip20) as string;
    // update token we currently have
    destSnip20 = other
  }

  // After all paths have been processed, we know the final token
  const destTokenInfo = contracts.find(c=>c.address === destSnip20)?.tokenInfo;
  if (!destTokenInfo) throw `Router swap ${tx.transactionHash} destination ${destSnip20} is an unknown token?!`

  const amountRecieved: number = parseInt(amountOut) / Math.pow(10, destTokenInfo.decimals)

  return {
    type: 'Shade Protocol: Router Swap',
    amount: [`-${amount} ${tokenInfo.symbol}`, `+${amountRecieved} ${destTokenInfo.symbol}`],
  }
}

const handleAlterPayDeposit = async ({contract, msg, sent_funds}: ExecuteMsg, tx: TxResponse, amount: number, tokenInfo: TokenInfo): Promise<PartMsgResponse> => {

  return {
    type: 'AlterPay: Deposit',
    amount: [`${amount} ${tokenInfo.symbol}`],
  }
}

const handleAlterPayExecute = async ({contract, msg, sent_funds}: ExecuteMsg, tx: TxResponse, handleMsg: string): Promise<PartMsgResponse> => {
  switch (handleMsg) {
    case 'send_tokens': {
      console.log('Send Msg', msg)
      const {amount, token} = msg.send_tokens
      const tokenInfo = await getTokenInfo(token);
      const float = parseInt(amount) / Math.pow(10, tokenInfo.decimals)

      return {
        type: 'AlterPay: Send',
        amount: [`-${float} ${tokenInfo.symbol}`],
      }
    }
    case 'withdraw_tokens': {
      console.log('Withdraw Msg', msg)
      const {amount, token} = msg.withdraw_tokens
      const tokenInfo = await getTokenInfo(token);
      const float = parseInt(amount) / Math.pow(10, tokenInfo.decimals)

      return {
        type: 'AlterPay: Withdraw',
        amount: [`${float} ${tokenInfo.symbol}`],
      }
    }
    default: {
      return {
        type: `AlterPay: ${handleMsg}`,
        amount: [],
      }
    }
  }

}

export default processTx;