import { Row, Col, Spinner, Table, Button } from "react-bootstrap";
import { useUser } from "./Contexts/UserContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TxResponse } from "secretjs";
import TxRow from "./TxRow";
import JsonModal from "./JsonModal";

const TxTable = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<TxResponse[]>([]);
  const [showJson, setShowJson] = useState<any>()

  useEffect(()=>{
    if (!user) return;

    loadTxs();
  },[user])

  const loadTxs = async () => {
    try {
      if (!user) throw new Error('Wallet Not Connected')
      setLoading(true);

      const conditions = [
        `transfer.sender='${user.address}'`,
        `transfer.recipient='${user.address}'`,
        `message.action='/secret.compute.v1beta1.MsgExecuteContract' AND message.sender='${user.address}'`
      ]

      const allResults = [];
      for (let i = 0; i < conditions.length; i++){
        const data = await user.client.query.txsQuery(conditions[i]);

        allResults.push(...data);
      }

      const unique = [...new Map(allResults.map((tx) => [tx.transactionHash, tx])).values()];
      unique.sort((a,b) => b.height - a.height);

      // console.log(unique);
      setTransactions(unique);

    } catch(err: any){
      console.error(err)
      toast.error(err.toString())
    } finally {
      setLoading(false);
    }
  }

  if (!user) return (
    <Row className='justify-content-center'>
      <Col xs='auto'>
        <h2>Connect wallet to load transactions.</h2>
      </Col>
    </Row>
  )

  if (loading) return (
    <Row className='justify-content-center'>
      <Col xs='auto'>
        <Spinner />
      </Col>
    </Row>
  )

  return (
    <>
    <Row className='justify-content-end mb-2'>
      <Col xs='auto'>
        <Button type='button' onClick={loadTxs} disabled={loading}>
          <>
            Refresh
            { loading && <Spinner /> }
          </>
        </Button>
      </Col>
    </Row>
    <Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Hash</th>
            <th>Result</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Height</th>
            <th>Time</th>
            <th>JSON</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map(tx=>
            <TxRow tx={tx} showJson={(data) => setShowJson(data)} />
          )}
        </tbody>
      </Table>
    </Row>
    <JsonModal show={!!showJson} json={showJson} hide={()=>setShowJson(undefined)} />
    </>
  )
}

export default TxTable;