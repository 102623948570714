import { TxResponse } from "secretjs";
import { decodeAll, truncate } from "./Utils";
import { ReactElement, useEffect, useState } from "react";
import processTx, { MsgResponse } from "./Utils/processTx";
import { useUser } from "./Contexts/UserContext";
import { Button, Spinner } from "react-bootstrap";

const TxRow = ({tx, showJson}: {tx: TxResponse, showJson: (data: any)=>void}) => {
  const {user} = useUser();

  const [processed, setProcessed] = useState<MsgResponse[]>()

  useEffect(()=>{
    if (!tx) return;
    handleTx()
  },[tx])
  
  const showTx = () => {
    const toShow = {...tx};
    decodeAll(toShow.events);
    showJson(toShow);
  }

  const handleTx = async () => {
    if (!user) throw new Error('Wallet not connected!')
    const result = await processTx(tx, user.address);
  setProcessed(result);
  }

  if (!processed) return (
    <tr id={`${tx.transactionHash}-0`}>
      <td><ExplorerLink hash={tx.transactionHash} /></td>
      <td>{
        tx.code ?
          <span style={{color: 'red'}}>Failure</span>
        : 
          <span style={{color: 'green'}}>Success</span>
        }</td>
      <td><Spinner /></td>
      <td><Spinner /></td>
      <td>{tx.height}</td>
      <td>{tx.height}</td>
    </tr>
  )
  
  const amountContents = processed.map((p, i)=>p.amount.map(a=>{
    return (
      <>
        {a}
        {processed.length !== i-1 && <br />}
      </>
    )
  }))
  const typeContents = processed.map(p=>p.type)

  return (
    // <>
    //   {
    //     processed.map(pm=>{
    //       return (
            <tr id={`${tx.transactionHash}`}>

              <td><ExplorerLink hash={tx.transactionHash} /></td>
              <td>{
                tx.code ?
                  <span style={{color: 'red'}}>Failure</span>
                : 
                  <span style={{color: 'green'}}>Success</span>
                }</td>
              {/* <td>{pm.type}</td>
              <td>{pm.amount.map((a, i)=>
                <>
                  {a} {i !== pm.amount.length-1 && <br/>}
                </>
              )}</td> */}
              <MultiMessageCell contents={typeContents} />
              <MultiMessageCell contents={amountContents} />
              <td>{tx.height}</td>
              <td style={{minWidth: '128px'}}>{new Date(tx.timestamp).toLocaleString()}</td>
              <td><Button type='button' onClick={showTx}>Show</Button></td>
            </tr>
    //       )
    //     })
    //   }
    // </>
  )
}

type MultiMessageCellProps = {
  contents: any[]
}

const MultiMessageCell = ({contents}: MultiMessageCellProps) => {
  return (
    <td>
      {contents.map((c, i)=>
        <>
          <div style={{minHeight: '64px'}}>
            {c}
          </div>
          {contents.length > 1 && contents.length > i+1 && <hr />}
        </>
      )}
      {/* <div>

      </div>
      <hr />
      <div>

      </div> */}
    </td>
  )
}

export default TxRow;


export const ExplorerLink = ({hash}: {hash: string}) => <a target='_blank' rel='noreferrer noopener' href={`https://dev.mintscan.io/secret/tx/${hash}`}>{truncate(hash)}</a>