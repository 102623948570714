import { tokens } from "../stores/tokenConfig";

type Denom = {
  denom: string;
  decimals: number;
  symbol: string;
}

export const Denoms: Denom[] = [
  {
    denom: 'uscrt',
    decimals: 6,
    symbol: 'SCRT',
  }
]

export const findDenom = (denom: string) => {
  let known = Denoms.find(d=>d.denom === denom)
  if (!known){
    const token = tokens.find(t=>t.withdrawals.find(w=>w.denom === denom));
    if (token) {
      known = {
        denom,
        decimals: token.decimals,
        symbol: token.name
      } as Denom
    } else {
      // console.log('Not Found!')
    }
  }
  // console.log('Returning', known)
  return known;
}